var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"pa-6"},[_c('div',[_c('header2',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("Billing Accounts "),_c('v-spacer'),(
          _vm.consultant.consultantPayoneerAccounts.length || _vm.consultant.consultantBankAccounts.length
        )?_c('buttonset',[_c('secondary-button',{on:{"click":function($event){_vm.showPaymentModal = true}}},[_vm._v("Add account")])],1):_vm._e()],1)],1),_c('v-row',[_vm._l((_vm.consultant.consultantPayoneerAccounts),function(account){return _c('v-col',{key:`payoneer_accont_${account.id}`,staticClass:"pa-2",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"payment-method",attrs:{"outlined":""}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pb-0 pr-1",attrs:{"cols":"2"}},[_c('img',{staticClass:"mt-2",staticStyle:{"max-width":"100%"},attrs:{"src":"/payoneer_logo.svg","alt":"Payoneer"}})]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"10"}},[_c('v-card-title',{staticClass:"pb-1",staticStyle:{"word-wrap":"anywhere"}},[_vm._v(_vm._s(account.customerId)+" "),_c('v-spacer'),_c('Menu',{attrs:{"icon":"la-angle-right","actions":{
                  Edit: () => {
                    _vm.onEditBillingAccount(account)
                  },
                  Remove: () => {
                    _vm.showDeleteDialog = true
                    _vm.activeAccountName = account.customerId
                    _vm.activeAccount = account
                  },
                }}})],1),_c('v-card-text',[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-0"},[_c('div',{staticClass:"d-block grey--text"},[_vm._v("Customer ID")]),_c('div',{staticClass:"black--text"},[_vm._v(_vm._s(account.customerId))])]),_c('v-col',{staticClass:"pa-0"},[_c('div',{staticClass:"d-block grey--text"},[_vm._v("Email address")]),_c('div',{staticClass:"black--text"},[_vm._v(_vm._s(account.emailAddress))])])],1),_c('div',{staticClass:"text-right"},[_c('StateChip',{attrs:{"state":account.state}})],1)],1)],1)],1)],1)],1)}),_vm._l((_vm.consultant.consultantBankAccounts),function(account){return _c('v-col',{key:`bank_accont_${account.id}`,staticClass:"pa-2",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"payment-method",attrs:{"outlined":""}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pb-0 pr-1 text-center",attrs:{"cols":"2"}},[_c('v-icon',{staticClass:"mt-3",attrs:{"x-large":""}},[_vm._v("la-landmark")])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"10"}},[_c('v-card-title',{staticClass:"pb-1"},[_vm._v(_vm._s(account.bankName)+" "),_c('v-spacer'),_c('Menu',{attrs:{"icon":"la-angle-right","actions":{
                  Edit: () => {
                    _vm.onEditBillingAccount(account)
                  },
                  Remove: () => {
                    _vm.showDeleteDialog = true
                    _vm.activeAccountName = account.bankName
                    _vm.activeAccount = account
                  },
                }}})],1),_c('v-card-text',[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-0"},[_c('div',{staticClass:"d-block grey--text"},[_vm._v("Account Number")]),_c('div',{staticClass:"black--text"},[_vm._v(_vm._s(account.accountNumber))])]),_c('v-col',{staticClass:"pa-0"},[_c('div',{staticClass:"d-block grey--text"},[_vm._v("Country")]),_c('div',{staticClass:"black--text"},[_vm._v(_vm._s(account.country.name))])])],1),_c('div',{staticClass:"text-right"},[_c('StateChip',{attrs:{"state":account.state}})],1)],1)],1)],1)],1)],1)})],2),(
      !_vm.consultant.consultantPayoneerAccounts.length && !_vm.consultant.consultantBankAccounts.length
    )?_c('empty-model',{attrs:{"button":"Add billing account"},on:{"click":function($event){_vm.showPaymentModal = true}}},[_vm._v(" You haven't added any billing accounts. "),_c('br'),_vm._v(" You can add your Bank account or Payoneer account ")]):_vm._e(),_c('Modal',{attrs:{"title":"Billing Account"},on:{"close":function($event){_vm.activeAccount = null}},model:{value:(_vm.showPaymentModal),callback:function ($$v) {_vm.showPaymentModal=$$v},expression:"showPaymentModal"}},[_c('BillingAccountForm',{attrs:{"account":_vm.activeAccount},on:{"save":() => {
          _vm.showPaymentModal = false
          _vm.activeAccount = null
        }}})],1),_c('ConfirmDialog',{attrs:{"loading":_vm.loading},on:{"confirm":_vm.onDeleteBillingAccount},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}},[_c('span',[_vm._v("Are you want to remove this billing account from your account? "),_c('b',[_vm._v(_vm._s(_vm.activeAccountName))])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }