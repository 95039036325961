
import { Component, Emit, Prop, Vue } from "vue-property-decorator"

@Component
export default class MiniNav extends Vue {
  @Prop() readonly value!: any
  @Prop() readonly items!: { key: string; text: string }[]

  @Emit("input")
  onInput(value: any) {
    return value
  }
}
