
import { Vue, Component, Prop } from "vue-property-decorator"
import GroupedAvatar from "@/components/profile/GroupedAvatar.vue"
import AppraisalDetail from "@/views/consultant/AppraisalDetail.vue"
import { MissionPerformanceAppraisal } from "@/gql"

@Component({
  components: {
    GroupedAvatar,
    AppraisalDetail,
  },
})
export default class AppraisalCard extends Vue {
  showAppraisalModal = false
  selectedAppraisal: any = null
  @Prop({ required: true }) readonly appraisal!: {
    mission: string
    appraisals: MissionPerformanceAppraisal[]
  }

  onShowAppraisalModal(appraisal: any) {
    this.showAppraisalModal = true
    this.selectedAppraisal = appraisal
  }

  get appraisees() {
    return this.appraisal.appraisals.map((item) => {
      return {
        alt: item.appraisee.name,
        id: item.id,
        role: item.appraisee.jobTitle?.name,
        src: item.appraisee.user?.avatar?.url,
      }
    })
  }
}
