
import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator"
import {
  Country,
  ApplicantInput,
  ConsultantProfile,
  ConsultantWorkExperience,
  AddConsultantWorkExperienceMutation,
  AddConsultantWorkExperienceMutationMutation,
  UpdateConsultantWorkExperienceMutationMutation,
  UpdateConsultantWorkExperienceMutation,
  ConsultantWorkExperienceInput,
} from "@/gql"
import CompanyCombo from "@/components/widgets/input/CompanyCombo.vue"
import CountrySelect from "@/components/widgets/input/CountrySelect.vue"

@Component({
  components: {
    CompanyCombo,
    CountrySelect,
  },
})
export default class AddWorkExperienceForm extends Vue {
  @Prop() readonly experience!: ConsultantWorkExperience
  @Prop() readonly applicant!: ApplicantInput
  @Prop() readonly consultant!: ConsultantProfile

  currentlyWorkHere = false
  mutating = false

  defaultForm: ConsultantWorkExperience = {
    id: 0,
    jobTitle: "",
    company: null,
    description: "",
    startDate: null,
    endDate: null,
    consultantWorkExperienceLocations: [],
  }
  consultantWorkExperienceLocations: Country[] = []
  form = { ...this.defaultForm }

  @Watch("experience", {
    immediate: true,
  })
  populateForm() {
    if (this.experience) {
      this.form = { ...this.experience }
      this.consultantWorkExperienceLocations =
        this.experience.consultantWorkExperienceLocations.map((location) => location.country)
      // If custom company
      if (this.experience.otherCompany) {
        this.form.company = this.experience.otherCompany as any
      }

      if (!this.form.endDate) {
        this.currentlyWorkHere = true
      }
    } else {
      this.form = { ...this.defaultForm }
    }
  }

  @Watch("currentlyWorkHere")
  onCurrentlyWorkHereChange(value: boolean) {
    if (value) {
      this.form.endDate = undefined
    }
  }

  @Emit("save")
  saveComplete(workExperience: ConsultantWorkExperience) {
    // Set locations and id on form
    this.form.consultantWorkExperienceLocations = [
      ...workExperience.consultantWorkExperienceLocations,
    ]
    this.form.id = workExperience.id

    const data = {
      ...workExperience,
      wasRecentlyCreated: !this.experience,
    }

    // Reset form
    this.form = { ...this.defaultForm }
    this.consultantWorkExperienceLocations = []
    this.currentlyWorkHere = false

    this.$refs.observer && (this.$refs.observer as any).reset()

    return data
  }

  async onSave() {
    if (typeof this.form.company === "string") {
      this.form.otherCompany = this.form.company
      this.form.company = null
    }

    const { jobTitle, description, startDate, endDate, otherCompany } = this.form

    const payload: ConsultantWorkExperienceInput = {
      jobTitle,
      description,
      startDate,
      endDate,
      otherCompany,
      companyId: this.form.company ? this.form.company.id : undefined,
      countryIds: this.consultantWorkExperienceLocations.map((l) => l.id),
    }

    if (!this.experience) {
      this.mutating = true

      // create
      const result = await this.mutate<AddConsultantWorkExperienceMutationMutation>({
        mutation: AddConsultantWorkExperienceMutation,
        variables: {
          applicant: this.applicant || undefined,
          consultantId: this.can("edit_talent") ? this.consultant.id : undefined,
          workExperience: { ...payload },
        },
        done: () => {
          this.mutating = false
        },
      })

      if (result.data?.addConsultantWorkExperience.workExperience) {
        this.addSuccess("Work experience added successfully!")

        this.saveComplete(result.data.addConsultantWorkExperience.workExperience)
      }
    } else {
      // update
      this.mutating = true

      const result = await this.mutate<UpdateConsultantWorkExperienceMutationMutation>({
        mutation: UpdateConsultantWorkExperienceMutation,
        variables: {
          applicant: this.applicant || undefined,
          consultantId: this.can("edit_talent") ? this.consultant.id : undefined,
          workExperienceId: this.experience.id,
          workExperience: { ...payload },
        },
        done: () => {
          this.mutating = false
        },
      })

      if (result.data?.updateConsultantWorkExperience.workExperience) {
        this.addSuccess("Work experience updated successfully!")

        this.saveComplete(result.data.updateConsultantWorkExperience.workExperience)
      }
    }
  }
}
