
import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import {
  ConsultantTalentInput,
  ConsultantTalent,
  UpdateConsultantTalentMutation,
  UpdateConsultantTalentMutationMutation,
} from "@/gql"
import { ConsultantStatuses } from "@/constants"
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"

@Component
export default class EditConsultantTalentForm extends Vue {
  @Prop() readonly consultant!: ConsultantTalent
  @Prop() readonly refetchQueries!: RefetchQueryDescription

  saving = false
  readonly consultantStatuses = ConsultantStatuses

  defaultForm: ConsultantTalentInput = {
    field: "",
    yearJoined: "",
    status: null,
    comments: "",
  }

  form = { ...this.defaultForm }

  @Watch("consultant", {
    immediate: true,
  })
  populateForm() {
    this.form.field = this.consultant.field
    this.form.yearJoined = this.consultant.yearJoined
    this.form.status = this.consultant.status
    this.form.comments = this.consultant.comments
  }

  async onSave() {
    try {
      this.saving = true

      const result = await this.$apollo.mutate<UpdateConsultantTalentMutationMutation>({
        mutation: UpdateConsultantTalentMutation,
        variables: {
          consultantId: this.consultant.id,
          talent: { ...this.form },
        },
        refetchQueries: this.refetchQueries,
      })

      if (result && result.data && result.data.updateConsultantTalent) {
        const error = result.data.updateConsultantTalent.error

        if (error) this.addMutationError(error)
        else this.saveComplete()
      }
    } catch (e) {
      this.addGraphQLError(e as Error)
    } finally {
      this.saving = false
    }
  }

  @Emit("save")
  saveComplete() {
    this.addSuccess("Consultant profile updated successfully")
    this.form = { ...this.defaultForm }
    return { ...this.form }
  }

  @Emit("close")
  onCancel() {
    this.form = { ...this.defaultForm }
  }
}
