
import { Component, Prop, Vue } from "vue-property-decorator"
import { ConsultantAvailabilities } from "@/constants"

@Component
export default class AvailabilityBadge extends Vue {
  @Prop()
  readonly availability!: string

  getAvailability(value: string) {
    return ConsultantAvailabilities.find((status) => status.value === value)?.text
  }

  getColor(value: string) {
    let color = ""
    switch (value) {
      case "available_full_time":
        color = "green"
        break
      case "available_part_time":
        color = "orange"
        break
      default:
        color = "red"
        break
    }
    return color
  }
}
