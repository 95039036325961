
import { Vue, Component, Prop } from "vue-property-decorator"
import {
  ApplicantInput,
  ConsultantProfile,
  DeleteConsultantSpokenLanguageMutation,
  DeleteConsultantSpokenLanguageMutationMutation,
  Language,
} from "@/gql"

@Component
export default class Languages extends Vue {
  @Prop({ required: true }) consultantSpokenLanguages!: Language[]
  @Prop() readonly applicant!: ApplicantInput
  @Prop() readonly editable!: boolean
  @Prop() readonly consultant!: ConsultantProfile
  @Prop({ default: false }) readonly flat!: boolean

  showDeleteDialog = false
  activeLanguage: Language | null = null
  activeIndex: number | null = null
  deleting = false

  onLanguageAction(action: string, index: number) {
    this.activeIndex = index
    this.activeLanguage = this.consultantSpokenLanguages[index]

    switch (action) {
      case "edit":
        // this.showAddEducationForm = true
        this.$emit("edit")
        break

      case "delete":
        this.showDeleteDialog = true
        break

      default:
        this.addError(`Unknown action: ${action}`)
        break
    }
  }

  async onLanguageDelete(index: number) {
    const language = this.consultantSpokenLanguages[index]

    // mutate
    try {
      this.deleting = true

      const result = await this.$apollo.mutate<DeleteConsultantSpokenLanguageMutationMutation>({
        mutation: DeleteConsultantSpokenLanguageMutation,
        variables: {
          languageId: language.id,
          applicant: this.applicant || undefined,
          consultantId: this.can("edit_talent") ? this.consultant.id : undefined,
        },
      })

      if (result && result.data) {
        const error = result.data.deleteConsultantSpokenLanguage.error
        if (error) this.addMutationError(error)
        else {
          this.addSuccess("Language deleted successfully")
          this.consultantSpokenLanguages.splice(index, 1)
          this.showDeleteDialog = false
          this.activeLanguage = null

          this.$emit("save")
        }
      }
    } catch (e: any) {
      this.addGraphQLError(e)
      this.$emit("error", e)
    } finally {
      this.deleting = false
    }
  }
}
