
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator"
import {
  ConsultantProfileFragmentFragment,
  SetConsultantTrainingTagsMutationMutation,
  SetConsultantTrainingTagsMutation,
  SetConsultantTrainingTagsMutationMutationVariables,
  TrainingTagsQuery,
  TalentPoolQuery,
} from "@/gql"
import WorkExperience from "@/components/consultant/WorkExperience.vue"
import Education from "@/components/consultant/Education.vue"
import Languages from "@/views/consultant/Languages.vue"
import BasicInfo from "@/components/consultant/BasicInfo.vue"
import EditConsultantTalentForm from "@/components/forms/EditConsultantTalentForm.vue"
import AppDetailPage from "@/components/layout/AppDetailPage.vue"
import MiniNav from "@/components/layout/MiniNav.vue"
import BillingAccounts from "./BillingAccounts.vue"
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"
import ProfileHeaderInfo from "@/components/profile/ProfileHeaderInfo.vue"
import MissionsSection from "@/components/profile/MissionsSection.vue"
import AppraisalCard from "@/components/profile/AppraisalCard.vue"
import KeywordField from "@/components/fields/KeywordField.vue"
import DocumentCard from "@/components/content/DocumentCard.vue"

@Component({
  components: {
    MiniNav,
    Education,
    Languages,
    BasicInfo,
    BillingAccounts,
    AppDetailPage,
    WorkExperience,
    ProfileHeaderInfo,
    MissionsSection,
    AppraisalCard,
    KeywordField,
    EditConsultantTalentForm,
    DocumentCard,
  },
})
export default class ConsultantProfileView extends Vue {
  @Prop({ required: true }) readonly consultant!: ConsultantProfileFragmentFragment
  readonly trainingTagsQuery = TrainingTagsQuery
  readonly query = TalentPoolQuery

  @Prop() readonly editable?: boolean
  @Prop() readonly queries?: RefetchQueryDescription
  @Prop() readonly backRoute?: string

  recentMissionsActiveIndex = 3
  showAllRecentMissions = false
  basicInfoEditMode = false
  showEditModal = false
  editKeywords = false
  trainingTags: string[] = []
  tagsMutationLoading = false

  activeMenu = 0

  ongoingMissionsMax = 4
  recentMissionsMax = 4

  get consultantTrainingTags() {
    return (this.consultant && this.consultant.trainingTagList) || []
  }

  get refetchQueries(): RefetchQueryDescription {
    return [
      {
        query: this.query,
        variables: {
          filter: {
            idIn: [this.$route.params.id],
          },
          per: 1,
        },
      },
    ]
  }

  trainingTagsForm: string[] = []

  async onSaveTrainingTags() {
    await this.setConsultantTrainingTags(this.trainingTagsForm)
  }

  @Watch("consultant", { immediate: true })
  onConsultantChanged() {
    this.trainingTagsForm = [...this.consultantTrainingTags]
    this.trainingTags = [...this.consultantTrainingTags]
  }

  trainingTagsNameList(trainingTagsData: any) {
    if (trainingTagsData && trainingTagsData.trainingTags && trainingTagsData.trainingTags.data) {
      return trainingTagsData.trainingTags.data.map((x: any) => x.name)
    }
    return []
  }

  async setConsultantTrainingTags(trainingTags: string[]) {
    this.tagsMutationLoading = true
    try {
      // Mutate
      const result = await this.mutate<
        SetConsultantTrainingTagsMutationMutation,
        SetConsultantTrainingTagsMutationMutationVariables
      >({
        mutation: SetConsultantTrainingTagsMutation,
        variables: {
          consultantId: this.consultant?.id,
          trainingTags,
        },
        refetchQueries: this.queries,
        done: () => {
          this.tagsMutationLoading = false
          this.editKeywords = false
          this.$emit("save")
        },
      })
      // Done
      if (result.data) {
        if (result.data.setConsultantTrainingTags.error) {
          this.addMutationError(result.data.setConsultantTrainingTags.error)
        } else {
          this.addSuccess(`Training Tags updated successfully!`)
        }
      }
    } catch (e: any) {
      this.addGraphQLError(e)
    }
  }

  get sideMenuItems() {
    return [
      { text: "Profile", key: "profile" },
      {
        text: "Missions",
        key: "missions",
      },
      {
        text: "Billing",
        key: "billing",
      },
    ]
  }

  get missions() {
    return [...this.ongoingMissions, ...this.recentMissions]
  }

  get isTalent(): boolean {
    return this.can("edit_talent") && ["approved", "shortlisted"].includes(this.consultant?.state)
  }

  get ongoingMissions() {
    return this.consultant?.missions!.filter((mission) => mission.state === "execution_stage") || []
  }

  get recentMissions() {
    return this.consultant?.missions!.filter((mission) => mission.state == "completed") || []
  }

  get ongoingApprasals() {
    return (
      this.ongoingMissions
        .filter((mission) => mission.appraisals.length > 0)
        .map((mission) => {
          return {
            mission: mission.name ? mission.name : mission,
            appraisals: mission.appraisals,
          }
        }) || []
    )
  }

  get otherAppraisals() {
    return (
      this.consultant
        ?.missions!.filter(
          (mission) => mission.state !== "execution_stage" && mission.appraisals.length > 0
        )
        .map((mission) => {
          return {
            mission: mission.name,
            appraisals: mission.appraisals,
          }
        }) || []
    )
  }

  get allAppraisals() {
    return [...this.ongoingApprasals, ...this.otherAppraisals]
  }

  get isOwnProfile() {
    return this.currentUser?.consultant?.id == this.consultant?.id
  }

  @Emit("save")
  onSave() {
    return true
  }
}
