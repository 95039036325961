
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator"

@Component
export default class KeywordField extends Vue {
  @Prop({ required: true }) readonly value!: string[] | null

  @Prop({ required: false }) readonly items?: string[]
  @Prop({ default: true }) readonly returnObject!: boolean
  @Prop({ default: "Add new keyword" }) readonly placeholder?: string

  @Prop()
  readonly required?: boolean
  @Prop()
  readonly label?: string

  selectedItems: string[] = []
  search: string | null = null
  currentItems: string[] = []

  get attrs() {
    const { label, ...attrs } = this.$attrs
    return attrs
  }

  get disabled() {
    return this.$attrs.disabled
  }

  @Watch("items", { immediate: true, deep: true })
  onItemsChange() {
    if (this.items) {
      const combinedItems = this.currentItems.concat(this.items)
      const uniqueItems = combinedItems.filter((item, pos) => combinedItems.indexOf(item) === pos)
      this.currentItems = [...uniqueItems]
    }
  }

  @Emit("input")
  onNewKeyword() {
    let keyword = this.search?.trim()

    if (!keyword) return

    const keywordExists = this.currentItems.includes(keyword)

    if (!keywordExists) {
      this.currentItems.push(keyword)
      this.selectedItems.push(keyword)
    }
    this.search = null
    return this.selectedItems
  }

  @Watch("value", { immediate: true, deep: true })
  onValueChange() {
    if (this.value && this.value.length) {
      this.selectedItems = [...this.value]
    }
  }

  removeKeyword(item: string) {
    this.selectedItems.splice(this.selectedItems.indexOf(item), 1)
    this.currentItems.splice(this.currentItems.indexOf(item), 1)
    this.$emit("input", this.selectedItems)
  }
}
