
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

@Component
export default class GroupedAvatar extends Vue {
  @Prop({ required: true }) listAvatars!: any
  stackedMenu = false

  getInitials(name: string) {
    const names = name.split(" ")
    const initials = names.map((n) => n.charAt(0)).join("")
    return initials.toUpperCase()
  }

  get avatarsStackedLimited() {
    return this.listAvatars && this.listAvatars.length > 0 ? this.listAvatars.slice(0, 4) : null
  }
}
