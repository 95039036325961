
import { SurveyAssignmentFragmentFragment, SurveyQuestion } from "@/gql"
import { Component, Prop, Vue } from "vue-property-decorator"
import SurveyForm from "@/components/surveys/SurveyForm.vue"
import ScaleInput from "@/components/fields/ScaleInput.vue"

type Question = SurveyQuestion
type AssignmentType = SurveyAssignmentFragmentFragment & {
  questions?: Question[]
}

@Component({
  components: {
    ScaleInput,
    SurveyForm,
  },
})
export default class AppraisalDetail extends Vue {
  @Prop({ required: true }) readonly appraisal!: any
  selectedAssignment: AssignmentType = this.appraisal.appraisals[0].surveyAssignment
  answers: { question_id: string; choice: any }[] = []
  showFormDialog = false

  async mounted() {
    this.setQuestions(this.appraisal.appraisals[0].surveyAssignment)
  }

  getInitials(name: string) {
    const names = name.split(" ")
    const initials = names.map((n) => n.charAt(0)).join("")
    return initials.toUpperCase()
  }

  showDialog() {
    this.showFormDialog = true
  }

  setSelectedAssignment(assignment: AssignmentType) {
    this.selectedAssignment = assignment
    this.setQuestions(assignment)
  }

  setQuestions(assignment: AssignmentType) {
    this.answers = []

    if (assignment && assignment.survey) {
      // Get survey questions
      const questions = assignment.survey.questions

      if (assignment.response) {
        questions &&
          questions.forEach((q) => {
            // Get answer for each question
            const answer = (assignment.response as any[]).find((a) => {
              // Find answer by question id
              return a.question_id === q.id
            })

            this.answers.push({
              question_id: q.id,
              choice: answer?.choice || "N/A",
            })
          })
      }
    }
  }

  getAnswers(question: any) {
    // Array of choices
    let answers = this.answers.filter((a) => a.question_id === question.id).map((a) => a.choice)

    // Get aggregate answer based on question type
    switch (question.questionType) {
      case "scale": {
        if (answers.length === 0) return 0

        // Find average of choices
        const sum = answers.reduce((accu, curr) => accu + curr)
        return Math.round(sum / answers.length)
      }

      case "scored_single_choice":
        // Get option description as choice
        answers = answers.map((choice) => {
          const option = (question.options as any[]).find((o) => {
            return o.label === choice
          })

          return option?.description || "N/A"
        })

        return answers

      default:
        return answers
    }
  }
}
