
import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator"
import InstitutionSelect from "@/components/widgets/input/InstitutionSelect.vue"
import {
  AddConsultantEducationMutation,
  AddConsultantEducationMutationMutation,
  ApplicantInput,
  ConsultantProfile,
  ConsultantEducation,
  ConsultantEducationInput,
  UpdateConsultantEducationMutation,
  UpdateConsultantEducationMutationMutation,
} from "@/gql"

@Component({
  components: {
    InstitutionSelect,
  },
})
export default class AddEducationForm extends Vue {
  @Prop() readonly consultant?: ConsultantProfile
  @Prop() readonly education?: ConsultantEducation
  @Prop() readonly applicant?: ApplicantInput

  mutating = false

  degrees = [
    "Bachelor of Arts",
    "Bachelor of Science",
    "Bachelor of Fine Arts",
    "Master of Arts",
    "Master of Science",
    "Master of Fine Arts",
    "Master of Business Administration",
    "Doctorate",
  ]

  // form
  defaultForm: ConsultantEducation = {
    id: 0,
    name: "",
    institution: null,
    degree: "",
    startDate: null,
    endDate: null,
    grade: "",
  }
  form = { ...this.defaultForm }

  @Watch("education", {
    immediate: true,
  })
  populateForm() {
    if (this.education) {
      this.form = { ...this.education }
      // Custom institution
      if (this.education.institutionName) {
        this.form.institution = this.education.institutionName as any
      }
    } else {
      this.form = { ...this.defaultForm }
    }
  }

  async onSave() {
    const { name, degree, grade, startDate, endDate } = this.form,
      payload: ConsultantEducationInput = {
        name,
        degree,
        grade,
        startDate,
        endDate,
      }

    // Custom institution
    if (typeof this.form.institution === "string") payload.institutionName = this.form.institution
    else payload.institutionId = this.form.institution!.id

    this.mutating = true

    if (!this.education) {
      this.createEducation(payload)
    } else {
      this.updateEducation(payload)
    }
  }

  async createEducation(payload: ConsultantEducationInput) {
    // Create
    const result = await this.mutate<AddConsultantEducationMutationMutation>({
      mutation: AddConsultantEducationMutation,
      variables: {
        applicant: this.applicant || undefined,
        consultantId: this.can("edit_talent") ? this.consultant?.id : undefined,
        education: { ...payload },
      },
      done: () => {
        this.mutating = false
      },
    })

    if (result.data?.addConsultantEducation.education) {
      this.addSuccess("Education added successfully!")
      this.saveComplete(result.data.addConsultantEducation.education)
    }
  }

  async updateEducation(payload: ConsultantEducationInput) {
    // Update
    const result = await this.mutate<UpdateConsultantEducationMutationMutation>({
      mutation: UpdateConsultantEducationMutation,
      variables: {
        applicant: this.applicant || undefined,
        consultantId: this.can("edit_talent") ? this.consultant!.id : undefined,
        educationId: this.education!.id,
        education: { ...payload },
      },
      done: () => {
        this.mutating = false
      },
    })

    if (result.data?.updateConsultantEducation.education) {
      this.addSuccess("Education updated successfully!")
      this.saveComplete(result.data.updateConsultantEducation.education)
    }
  }

  @Emit("save")
  saveComplete(education: ConsultantEducation) {
    // Set id on form
    this.form.id = education.id

    const data = {
      ...education,
      wasRecentlyCreated: !this.education,
    }

    // Reset form
    this.form = { ...this.defaultForm }
    this.$refs.observer && (this.$refs.observer as any).reset()

    return data
  }
}
