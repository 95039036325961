import { render, staticRenderFns } from "./GroupedAvatar.vue?vue&type=template&id=a2011cb0&scoped=true&"
import script from "./GroupedAvatar.vue?vue&type=script&lang=ts&"
export * from "./GroupedAvatar.vue?vue&type=script&lang=ts&"
import style0 from "./GroupedAvatar.vue?vue&type=style&index=0&id=a2011cb0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2011cb0",
  null
  
)

export default component.exports