
import { MissionLifecycleBasic } from "@/gql"
import { Vue, Prop, Component } from "vue-property-decorator"

@Component
export default class MissionsSection extends Vue {
  @Prop() readonly missions!: MissionLifecycleBasic[]
  @Prop({ default: false }) readonly flat!: boolean

  getMissionStatus(value: string) {
    switch (value) {
      case "execution_stage":
        return "Ongoing"
      case "completed":
        return "Completed"
      default:
        break
    }
  }
}
