
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import AddWorkExperienceForm from "./forms/AddWorkExperienceForm.vue"
import {
  ApplicantInput,
  ConsultantProfile,
  ConsultantWorkExperience,
  DeleteConsultantWorkExperienceMutation,
  DeleteConsultantWorkExperienceMutationMutation,
} from "@/gql"

@Component({
  components: {
    AddWorkExperienceForm,
  },
})
export default class WorkExperience extends Vue {
  @Prop() readonly consultant!: ConsultantProfile
  @Prop() readonly applicant!: ApplicantInput
  @Prop() readonly editable!: boolean
  @Prop({ default: true }) readonly showTitle!: boolean
  @Prop({ default: false }) readonly flat!: boolean

  showAddExperienceModal = false
  showDeleteDialog = false
  deleting = false

  workExperiences: ConsultantWorkExperience[] = []
  activeWorkExperience: ConsultantWorkExperience | null = null
  activeIndex: number | null = null

  onSaveExperience(experience: ConsultantWorkExperience & { wasRecentlyCreated: boolean }) {
    // Update work experiences
    if (experience.wasRecentlyCreated) {
      this.workExperiences.push(experience)
    } else {
      // Update work experience in local state
      const index = this.workExperiences.findIndex((exp) => exp.id === experience.id)
      this.workExperiences[index] = experience
    }
    this.showAddExperienceModal = false
    this.$emit("save", this.workExperiences)
  }

  onWorkExperienceAction(action: string, index: number) {
    this.activeIndex = index
    this.activeWorkExperience = this.workExperiences[index]

    switch (action) {
      case "edit":
        this.showAddExperienceModal = true
        break

      case "delete":
        this.showDeleteDialog = true
        break

      default:
        this.addError(`Unknown action: ${action}`)
        break
    }
  }

  async onWorkExperienceDelete(index: number) {
    const workExperience = this.workExperiences[index]

    // mutate
    try {
      this.deleting = true

      const result = await this.$apollo.mutate<DeleteConsultantWorkExperienceMutationMutation>({
        mutation: DeleteConsultantWorkExperienceMutation,
        variables: {
          workExperienceId: workExperience.id,
          applicant: this.applicant || undefined,
          consultantId: this.can("edit_talent") ? this.consultant.id : undefined,
        },
      })

      if (result && result.data) {
        const error = result.data.deleteConsultantWorkExperience.error
        if (error) this.addMutationError(error)
        else {
          this.addSuccess("Work experience deleted successfully")
          this.workExperiences.splice(index, 1)
          this.showDeleteDialog = false
          this.activeWorkExperience = null

          this.$emit("save", this.workExperiences)
        }
      }
    } catch (e: any) {
      this.addGraphQLError(e)
      this.$emit("error", e)
    } finally {
      this.deleting = false
    }
  }

  @Watch("consultant", {
    immediate: true,
  })
  onConsultantChanged() {
    if (this.consultant && this.consultant.consultantWorkExperiences) {
      this.workExperiences = [...this.consultant.consultantWorkExperiences]
    }
  }

  isComplete() {
    const e = this.workExperiences
    return !!(e && e.length)
  }
}
