
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import {
  ApplicantInput,
  ConsultantEducation,
  ConsultantProfile,
  DeleteConsultantEducationMutation,
  DeleteConsultantEducationMutationMutation,
} from "@/gql"
import AddEducationForm from "./forms/AddEducationForm.vue"

@Component({
  components: {
    AddEducationForm,
  },
})
export default class Education extends Vue {
  @Prop() readonly consultant!: ConsultantProfile
  @Prop() readonly applicant!: ApplicantInput
  @Prop() readonly editable!: boolean
  @Prop({ default: true }) readonly showTitle!: boolean
  @Prop({ default: false }) readonly flat!: boolean

  showAddEducationForm = false
  showDeleteDialog = false
  deleting = false

  consultantEducation: ConsultantEducation[] = []
  activeEducation: ConsultantEducation | null = null
  activeIndex: number | null = null

  onEducationSave(education: ConsultantEducation & { wasRecentlyCreated: boolean }) {
    if (education.wasRecentlyCreated) {
      this.consultantEducation.push(education)
    } else {
      // Update education in local state
      const index = this.consultantEducation.findIndex((e) => e.id === education.id)
      this.consultantEducation[index] = education
    }
    this.showAddEducationForm = false
    this.$emit("save", this.consultantEducation)
  }

  onEducationAction(action: string, index: number) {
    this.activeIndex = index
    this.activeEducation = this.consultantEducation[index]

    switch (action) {
      case "edit":
        this.showAddEducationForm = true
        break

      case "delete":
        this.showDeleteDialog = true
        break

      default:
        this.addError(`Unknown action: ${action}`)
        break
    }
  }

  async onEducationDelete(index: number) {
    const education = this.consultantEducation[index]

    // mutate
    try {
      this.deleting = true

      const result = await this.$apollo.mutate<DeleteConsultantEducationMutationMutation>({
        mutation: DeleteConsultantEducationMutation,
        variables: {
          educationId: education.id,
          applicant: this.applicant || undefined,
          consultantId: this.can("edit_talent") ? this.consultant.id : undefined,
        },
      })

      if (result && result.data) {
        const error = result.data.deleteConsultantEducation.error
        if (error) this.addMutationError(error)
        else {
          this.addSuccess("Work experience deleted successfully")
          this.consultantEducation.splice(index, 1)
          this.showDeleteDialog = false
          this.activeEducation = null

          this.$emit("save", this.consultantEducation)
        }
      }
    } catch (e) {
      this.addGraphQLError(e as Error)
      this.$emit("error", e)
    } finally {
      this.deleting = false
    }
  }

  @Watch("consultant", {
    immediate: true,
  })
  onConsultantChanged() {
    if (this.consultant && this.consultant.consultantEducations) {
      this.consultantEducation = [...this.consultant.consultantEducations]
    }
  }

  isComplete() {
    const e = this.consultantEducation
    return !!(e && e.length)
  }
}
